<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">财务扎帐</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-date-range label="日期" ref="dateRange" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startDate" :maxDate.sync="pageParam.params.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" :clearable='false' />
                <le-select-remote-search label="商户" v-model="pageParam.params.companyMerchId" :options="companyMerchantFilterOptions" placeholder="请选择" />
            </le-search-form>
            <le-pagview ref="financeZZ" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.financeZZList">
                <el-table 
                    ref="financeZZList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :border="true"
                    :span-method='mergeCols'
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="recordDate" label="日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordDate?row.recordDate:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="收款商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyMerchantName?row.companyMerchantName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="三方类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.tripartiteType | initDic(tripartiteTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="收支表收入" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.incomeCalcResult.indexOf('turnoverIncome')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.turnoverIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="收支表支出" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.expensesCalcResult.indexOf('turnoverExpenses')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.turnoverExpenses) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="订单表收入" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.incomeCalcResult.indexOf('orderIncome')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.orderIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="订单表支出" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.expensesCalcResult.indexOf('orderExpenses')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.orderExpenses) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="测试收入数据" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormatNotValue(row.testIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="测试支出数据" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ util.numFormatNotValue(row.testExpenses) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="三方支付收入" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.incomeCalcResult.indexOf('tripartiteIncome')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.tripartiteIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="三方支付支出" min-width="120">
                        <template slot-scope="{ row }">
                            <span :class="row.expensesCalcResult.indexOf('tripartiteExpenses')!=-1?'panel-cell-error':''">{{ util.numFormatNotValue(row.tripartiteExpenses) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="paymentType" 
                        label="状态" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="incomeStatus" 
                        label="扎帐结果" 
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.result | initDic(resultDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" min-width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handleToInfo(scope.row)" />
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="测试数据修正" placement="top">
                                <img src="../../assets/icon/option-edit.png" class="a-wh-16" @click="handleEditTest(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
        <finance-test-input ref="testInput" @handlerSuccess='editTestSuccess'></finance-test-input>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    import financeTestInput from './components/finance-test-input.vue'
    export default {
        components: { financeTestInput },
        data () {
            return {
                tableData: [],
                cosTableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.reconciliationCompanyPage,
                    method: "post",
                    params: {
                        companyMerchId: '',
                        endDate: '',
                        startDate: '',
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 6 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                companyMerchantFilterOptions: {
                    url: this.$Config.apiUrl.companyMerchantFilter,
                    method: "post",
                    params: {
                        name: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },
                tripartiteTypeDic: [],
                statusDic: [],
                resultDic: []
            }
        },
        created () {
            this.$getDic('tripartiteType').then(res=>{ this.tripartiteTypeDic = res; })
            this.$getDic('reconciliationStatus').then(res=>{ this.statusDic = res; })
            this.$getDic('reconciliationResult').then(res=>{ this.resultDic = res; })
            this.pageParam.params = {
                companyMerchId: '',
                endDate: this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss'),
                startDate: this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss'),
            }
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data.map(item=>{
                    let incomeArr = {
                        turnoverIncome: item.turnoverIncome,
                        orderIncome: item.orderIncome,
                        tripartiteIncome: (item.tripartiteIncome - item.testIncome)
                    }
                    let expensesArr = {
                        turnoverExpenses: item.turnoverExpenses,
                        orderExpenses: item.orderExpenses,
                        tripartiteExpenses: (item.tripartiteExpenses - item.testExpenses)
                    }
                    let incomeCalcResult = this.findLeastFrequentElementKey(incomeArr)
                    let expensesCalcResult = this.findLeastFrequentElementKey(expensesArr)
                    // console.log(incomeCalcResult,'incomeCalcResult');
                    // console.log(expensesCalcResult,'expensesCalcResult');
                    return {
                        ...item,
                        incomeCalcResult: incomeCalcResult,
                        expensesCalcResult: expensesCalcResult
                    }
                });
                this.getCosTableData()
            },
            allEqual(array) {
                if (array.length === 0) return true; // 空数组所有元素相等
                return array.every(item => item === array[0]);
            },
            findLeastFrequentElementKey(obj) {
                let keys = Object.keys(obj)
                let values = Object.values(obj)
                let valueCounts = {}; // 用于存储每个属性值的出现次数

                let isAllEqual = this.allEqual(values) //当所有数据都相等时，返回空；否则返回数据异常项的key
                if(isAllEqual) {
                    return []
                }
                values.map((item,index)=>{
                    if(valueCounts[item]) {
                        valueCounts[item]++
                    }else {
                        valueCounts[item] = 1
                    }
                })
                // 找到出现次数最少的元素的最小次数
                let minCount = Infinity;
                for (let element in valueCounts) {
                    if (valueCounts[element] < minCount) {
                        minCount = valueCounts[element];
                    }
                }

                // 返回出现次数等于最小次数的所有元素
                let leastFrequentElements = [];
                let leastFrequentKeys = [];
                for (let element in valueCounts) {
                    if (valueCounts[element] === minCount) {
                        leastFrequentElements.push(element);
                    }
                }
                // 当所有元素返回出现次数最少得元素的key
                for (const key in obj) {
                    const element = obj[key] ? obj[key].toString() : obj[key];
                    if(leastFrequentElements.indexOf(element) != -1) {
                        leastFrequentKeys.push(key)
                    }
                }
                return leastFrequentKeys;
            },

            handlerRest() {
                this.pageParam.params = {
                    companyMerchId: '',
                    endDate: this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss'),
                    startDate: this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss'),
                };
                this.$refs['dateRange'].value1 = [this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss'),this.$getDay.getTodayBeforeDays(2,'YYYY-MM-DD HH:mm:ss')]
                this.$refs['financeZZList'].clearFilter()
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['financeZZ'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleToInfo (datas) {
                this.$router.push({
                    path: '/finance/finance-accounting-process-details',
                    query: { 
                        startDate: datas.recordDate,
                        endDate: datas.recordDate,
                        companyMerchantId: datas.companyMerchantId,
                        companyMerchantName: datas.companyMerchantName,
                        tripartiteType: datas.tripartiteType
                    },
                });
            },
            handleEditTest (datas) {
                this.$refs['testInput'].form.id = datas.id
                this.$refs['testInput'].dialogVisible = true
            },
            editTestSuccess () {
                this.pageParam.freshCtrl++;
            },
            // 合并行
            mergeCols ({ row, column, rowIndex, columnIndex }) {
                if(columnIndex === 0 && columnIndex === 1){
                    for (let index = 0; index < this.cosTableData.length; index++) {
                        const element = this.cosTableData[index];
                        for (let c_index = 0; c_index < element.length; c_index++) {
                            if(rowIndex == element[c_index]){
                                if(c_index == 0){
                                    return {
                                        rowspan: element.length,
                                        colspan: 1
                                    }
                                }else{
                                    return {
                                        rowspan: 0,
                                        colspan: 0
                                    }
                                }   
                            }
                        }
                    }
                }
            },
            // 获取需要合并单元格的数据
            getCosTableData () {
                this.cosTableData = []
                let obj = {}
                // 将相同编号的数据储存到同一个数组中，并以该编号为key值添加到obj对象中 以便之后取用
                this.tableData.map((item,index)=>{
                    if(obj[item.companyMerchantId]){
                        obj[item.companyMerchantId].push(index)
                    }else{
                        obj[item.companyMerchantId] = []
                        obj[item.companyMerchantId].push(index)
                    }
                })
                // 将obj对象中数组长度大于1（即存在多个相同编号）的数据push到this.cosTableData中
                for (const key in obj) {
                    if(obj[key].length > 1)
                    this.cosTableData.push(obj[key])
                }
            },
            exportfile () {
                // this.$exfile({
                //     code: 3,
                //     fileName: this.pageParam.params.queryStartDate + ' 至 ' + this.pageParam.params.queryEndDate + ' 站点经营分析',
                //     startTime: this.pageParam.params.queryStartDate,
                //     endTime: this.pageParam.params.queryEndDate,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
    .panel-cell-error{
        color: #ffffff;
        background: #F56C6C !important;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        width: 100%;
    }
    .panel-cell-errorExpenses{
        color: #ffffff;
        background: #F56C6C !important;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        width: 100%;
    }
</style>